<template>
  <div
    :id="scrollId"
    style="padding: 48px 0px;"
  >
    <v-container>
      <v-row
        align="center"
        justify="space-between"
      >
        <v-col
          cols="12"
          md="7"
          :class="{'order-1': left}"
        >
          <base-info-card
            :title="title"
            pace="6"
            :class="{'justify-left': !left}"
            :right="!left"
            :align="left ? 'left' : 'right'"
            title-size="display-2"
          >
            <div
              v-if="titled"
              v-html="slideTitle"
            />
          </base-info-card>
        </v-col>
        <v-col
          cols="12"
          md="5"
          :class="{'order-0': left}"
        >
          <v-carousel
            v-model="currentSlide"
            cycle
            :height="$vuetify.breakpoint.mdAndUp ?
              '600px' : '300px'"
          >
            <v-carousel-item
              v-for="(item,i) in gallery"
              :key="i"
              style="flex-align:center; justify-content: center"
            >
              <v-img
                :contain="true"
                :src="getImgFromCollectionUrl(item)"
                :height="$vuetify.breakpoint.mdAndUp ?
                  '600px' : '300px'"
              />
            </v-carousel-item>
          </v-carousel>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
  import SectionHeadedDescription from '@/views/sections/reusable/HeadedDescription'
  import getImgFromCollectionUrl from '@/utils/getImgFromCollectionUrl.js'
  import 'slick-carousel/slick/slick.css'
  import SectionTrustedUs from '../TrustedUs'

  export default {
    name: 'SectionDescriptedSlider',
    components: {
      SectionTrustedUs,
      SectionHeadedDescription,
    },
    props: {
      scrollId: {
        type: String,
      },
      left: {
        type: Boolean,
      },
      title: {
        type: String,
      },
      gallery: {
        type: Array,
      },
    },
    data () {
      return {
        currentSlide: 0,
      }
    },
    computed: {
      slideTitle () {
        return this.titled && this.gallery ? this.gallery[this.currentSlide].title : `${this.currentSlide + 1}`
      },
      maxHeight () {
        return this.$vuetify.breakpoint.width >= this.mobileBreakPoint ? 'auto' : '600px'
      },
    },
    methods: {
      getImgFromCollectionUrl,
    },
  }
</script>

<style lang="scss">
  .slick{
    margin-top: -65px;
    padding-bottom: 15px;
    background: #595457;
    .slick-slide {
      border: 10px solid transparent;
      .v-image__image{
      }
      &>div{
        &>div{
          display: block!important;
        }
      }
    }
    .slick-current {
      border: 10px solid #e12b31;
      background: red;
    }
  }
</style>
<!--primary: '#e12b31',-->
<!--secondary: '#595457',-->
<!--accent: '#EADEDA',-->
