<template>
  <div>
    <section-descripted-slider
      scroll-id="realizationsGallery1"
      :title="translations.realizationsTitle1"
      :gallery="translations.realizationsGallery1"
      :left="$vuetify.breakpoint.mdAndUp"
    />
    <section-descripted-slider
      scroll-id="realizationsGallery2"
      :title="translations.realizationsTitle2"
      :gallery="translations.realizationsGallery2"
    />
    <section-descripted-slider
      scroll-id="realizationsGallery3"
      :title="translations.realizationsTitle3"
      :gallery="translations.realizationsGallery3"
      :left="$vuetify.breakpoint.mdAndUp"
    />
  </div>
</template>

<script>
  import { STORE_NAMESPACE_REALIZATIONS } from '@/views/realizations/Index.vue'
  import 'slick-carousel/slick/slick.css'
  import { mapState } from 'vuex'
  import SectionSlider from '@/views/sections/reusable/Slider'
  import SectionDescriptionText from './reusable/DescriptionText'
  import SectionDescriptedSlider from './reusable/DescriptedSlider'

  export default {
    name: 'SectionRealizationsSlider',
    components: {
      SectionDescriptedSlider,
      SectionDescriptionText,
      SectionSlider,
    },
    computed: {
      ...mapState(STORE_NAMESPACE_REALIZATIONS, [
        'translations',
      ]),
    },
  }
</script>
